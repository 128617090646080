import 'mdn-polyfills/NodeList.prototype.forEach';
import Stack from './Stack';

document.addEventListener('DOMContentLoaded', () => {
  if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {

    const stacks = document.querySelectorAll('.is-observed');
    if (!stacks.length) {
      return;
    }
    var halfHauter = window.innerHeight * (-0.5);

    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
        if(entry.isIntersecting){
          const stack = new Stack(entry.target);
          stack.mount();
        } else{
          const stack = new Stack(entry.target);
          stack.unmount();
        }
        });
      }, {rootMargin: "0px 0px "+ halfHauter+"px 0px"});
      document.querySelectorAll('.is-observed').forEach(stack => { observer.observe(stack) });
    }
  }
  else {
  }
});
