export default class Toggler {
  constructor(element, target, autoClose = false) {
    this.element = element;
    this.target = target;
    this.autoClose = autoClose;
    this.autoFocus = true;
    this.expanded = null;
    this.mounted = false;
    this.isWaiting = false;
    this.lastEventType = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);
    this.handleTarget = this.handleTarget.bind(this);
    this.handleToggling = this.handleToggling.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
  }

  handleAutoClose(e) {
    this.lastEventType = e.type;
    if (
      !this.target.contains(e.target)
      && !this.element.contains(e.target)
      && !this.isWaiting
    ) {
      this.hide();
      document.removeEventListener('click', this.handleAutoClose);
      document.removeEventListener('keyup', this.handleAutoClose);
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.target.dispatchEvent(new CustomEvent('toggling', { detail: { toggler: this } }));
    this.toggle();
  }

  handleKeyup(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.toggle();
    }
  }

  handleTarget(e) {
    if (e.detail.toggler.element !== this.element) {
      this.expanded = e.detail.toggler.expanded;
      this.element.setAttribute('aria-expanded', this.expanded);
      this.isWaiting = false;
      this.element.dispatchEvent(new CustomEvent('toggler:change', { detail: { toggler: this } }));
    }
  }

  handleToggling(e) {
    if (e.detail.toggler.element !== this.element) {
      this.isWaiting = true;
    }
  }

  handleTransitionEnd() {
    this.target.setAttribute('aria-hidden', 'true');
    this.target.classList.remove('is-hiding');
    this.element.dispatchEvent(new CustomEvent('toggler:hide', { detail: { toggler: this } }));
    this.target.removeEventListener('transitionend', this.handleTransitionEnd);
    this.target.dispatchEvent(new CustomEvent('update', { detail: { toggler: this } }));
  }

  handleAnimationEnd() {
    this.target.removeEventListener('transitionend', this.handleAnimationEnd);
    this.target.dispatchEvent(new CustomEvent('update', { detail: { toggler: this } }));
  }

  toggle() {
    if (this.expanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    if(this.mounted) {
      this.element.setAttribute('aria-expanded', 'true');
      this.target.addEventListener('animationend', this.handleAnimationEnd);
      this.target.setAttribute('aria-hidden', 'false');
      this.expanded = true;

      if(this.autoFocus) {
        this.target.focus();
      }

      this.target.classList.remove('is-hiding');
      this.element.dispatchEvent(new CustomEvent('toggler:show', { detail: { toggler: this } }));

      if (this.autoClose) {
        document.addEventListener('click', this.handleAutoClose);
        document.addEventListener('keyup', this.handleAutoClose);
      }
    }
  }

  hide(ignore = false) {
    if(this.mounted) {
      if (this.autoClose) {
        document.removeEventListener('click', this.handleAutoClose);
        document.removeEventListener('keyup', this.handleAutoClose);
      }

      if (ignore) {
        this.element.setAttribute('aria-expanded', 'false');
        this.target.setAttribute('aria-hidden', 'true');
        this.expanded = false;
      } else {
        this.target.addEventListener('transitionend', this.handleTransitionEnd);
        this.target.classList.add('is-hiding');
        this.expanded = false;
        this.element.setAttribute('aria-expanded', 'false');
        this.element.dispatchEvent(new CustomEvent('toggler:hiding', { detail: { toggler: this } }));
      }
    }
  }

  mount() {
    this.element.setAttribute('aria-controls', this.target.id);
    this.element.setAttribute('aria-expanded', 'false');
    this.target.setAttribute('aria-hidden', 'true');
    this.target.setAttribute('tabindex', '-1');
    this.expanded = false;
    this.mounted = true;

    if (this.element.tagName !== 'BUTTON') {
      this.element.setAttribute('role', 'button');
      this.element.setAttribute('tabindex', 0);
      this.element.addEventListener('keyup', this.handleKeyup);
    }

    this.element.addEventListener('click', this.handleClick);
    this.target.addEventListener('update', this.handleTarget);
    this.target.addEventListener('toggling', this.handleToggling);
    this.element.dispatchEvent(new CustomEvent('toggler:mounted', { detail: { toggler: this } }));
  }

  unmount() {
    this.element.removeAttribute('aria-controls');
    this.element.removeAttribute('aria-expanded');
    this.target.removeAttribute('aria-hidden');
    this.target.removeAttribute('tabindex');
    this.target.classList.remove('is-hiding');
    this.expanded = null;
    this.mounted = false;

    if (this.element.tagName !== 'BUTTON') {
      this.element.removeAttribute('role');
      this.element.removeAttribute('tabindex');
      this.element.removeEventListener('keyup', this.handleKeyup);
    }

    this.element.removeEventListener('click', this.handleClick);
    this.target.removeEventListener('update', this.handleTarget);
    this.target.removeEventListener('toggling', this.handleToggling);
    this.target.removeEventListener('transitionend', this.handleTransitionEnd);
    this.target.removeEventListener('transitionend', this.handleAnimationEnd);
    this.element.dispatchEvent(new CustomEvent('toggler:unmounted', { detail: { toggler: this } }));
  }
}
